import React, { useEffect } from 'react'
import '../Styles/List.css'
import ItemCard from './Item/ItemCard';
import Tools from '../Helpers/Tools';

const List = ({ list, type }) => {
    
    let nbPerLine = 5
    if (window.innerWidth === 1360 || window.innerWidth === 1366) { nbPerLine = 3 }
    else if (window.innerWidth === 1920) { nbPerLine = 5 }
    else if (window.innerWidth === 2560) { nbPerLine = 6 }

    while(list.length % nbPerLine !== 0) {
        list.push(null)
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            let nbPerLine = 5
            if (window.innerWidth >= 1360 || window.innerWidth >= 1366) { nbPerLine = 3 }
            else if (window.innerWidth >= 1920) { nbPerLine = 5 }
            else if (window.innerWidth >= 2560) { nbPerLine = 6 }

            while(list.length % nbPerLine !== 0) {
                list.push(null)
            }
        })
    }, [])
    

    return (
        <div className='List_container' >
            {
                list.map((el, id) => {
                    if(el !== null && (type === 'series' || type === 'animes')) {
                        el.seasons = el.seasons.sort((a, b) => {
                            return a.nb - b.nb
                        })
                    }
                    return (
                        <ItemCard 
                            item={el} 
                            type={type}
                            key={ Tools.uuid() }
                        />
                    )
                })
            }
        </div>
    )
}

export default List