import React, { useEffect, useState } from 'react'
import '../../Styles/ItemCard.css'
import Modal from '../Modal'
import AnimeModalItem from './AnimeModalItem'
import AppModalItem from './AppModalItem'
import EbookModalItem from './EbookModalItem'
import GameModalItem from './GameModalItem'
import MovieModalItem from './MovieModalItem'
import MusicModalItem from './MusicModalItem'
import SeriesModalItem from './SerieModalItem'
import SoftwareModalItem from './SoftwareModalItem'
import VideoModalItem from './VideoModalItem'

const ItemCard = ({ item, type }) => {
    const [modalVisible, setModalVisible] = useState(false)

    if(item) {        

        let info = ""
        let modalItem = null
        switch(type) {
            case "movies":
                info = "Sortie: " + item.releaseYear
                modalItem = (<MovieModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "series":
                info = "Saison: " + item.seasons.length
                modalItem = (<SeriesModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "animes":
                info = "Saison: " + item.seasons.length
                modalItem = (<AnimeModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "musics":
                info = "Nombre de piste: " + item.nbTrack
                modalItem = (<MusicModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "ebooks":
                info = "Pages: " + item.nbPages
                modalItem = (<EbookModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "games":
                info = "Plateforme: " + item.support
                modalItem = (<GameModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "softwares":
                info = "Editeur: " + item.editor
                modalItem = (<SoftwareModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "apps":
                info = "Editeur: " + item.editor
                modalItem = (<AppModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            case "videos":
                modalItem = (<VideoModalItem item={item} onClose={ (e) => { e.stopPropagation(); setModalVisible(false)} }/>)
                break;
            default:
                info = ""
        }

        return (
            <div 
                className='ItemCard_container'
                onClick={() => setModalVisible(true)}
            >
                <div className='card'>
                    <img
                        src={item.image}
                        alt={item.title}
                    />
                    <div>
                        <span>{item.title}</span>
                        <span>{info}</span>
                    </div>
                </div>
                <Modal
                    isShown={modalVisible}
                    onRequestClose={() => setModalVisible(false)}
                >
                    { modalItem }
                </Modal>
            </div>
        )
    }
    else {
        return (
            <div className='ItemCard_container' >
            </div>
        )
    }
}

export default ItemCard