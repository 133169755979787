import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import '../../Styles/ItemModal.css'
import Tools from '../../Helpers/Tools'

const SoftwareModalItem = ({ item, onClose }) => {

    return (
        <div key={item.title + '-' + item.image} className='ItemModal_container'>
            <div className='header' >
                <h2>{item.title}</h2>
                <button onClick={(e) => onClose(e)} >
                    <FontAwesomeIcon icon={ faArrowLeft } color='white' size='3x' />
                </button>
            </div>
            <div className='content' >
                <div className='left'>
                    <div className='img' >
                        <img src={item.image} alt={item.title} />
                    </div>
                    <div className='infoSection' >
                        <div>
                            <strong>Editeur: </strong>
                            {item.editor}
                        </div>
                        <div>
                            <strong>Langue: </strong>
                            {Tools.getNationality(item.language)}
                        </div>
                        <div>
                            <strong>Genres: </strong>
                            {item.genres.join(', ').substring(0, 20) + (item.genres.join(', ').length > 20 ? '...' : '')}
                        </div>
                    </div>
                </div>
                <div className='textSection' >
                    <div className='downloadButton' >
                        <button onClick={() => window.open(item.link, '_blank')} >Télécharger</button>
                    </div>
                    <div className='placeholder' >
                        {/* AD ? */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoftwareModalItem