import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import '../Styles/Header.css'
import DonateButton from './DonateButton'
import Tools from '../Helpers/Tools'

const Header = ({ search, setSearch, onSearch, type, setType }) => {

    return (
        <div className='Header_container' >
            <div className='donateSection' >
                <DonateButton />
            </div>
            <div className='searchSection' >
                
                <input 
                    className='searchBar' 
                    type='text' 
                    placeholder='Rechercher' 
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    onKeyDown={e => { if(e.key === "Enter"){ onSearch(search, type) } }}
                />
                <button 
                    className='searchButton'
                    onClick={() => onSearch(search, type)}
                    onKeyDown={e => { if(e.key === "Enter"){ onSearch(search, type) } }}
                >
                    <FontAwesomeIcon icon={ faSearch } color="white" style={{ fontSize: '1.5em' }} />
                </button>
            </div>
            <div className='typeSection' >
                <select 
                    className='dropdown'
                    onChange={e => {
                        onSearch(search, e.target.value)
                        setType(e.target.value)
                    }}
                    value={type}
                >
                    {
                        Tools.TYPES.map((el, id) => {
                            return (
                                <option key={el.label + "-" + el.value} value={el.value} >{el.label}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

export default Header