import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import React from 'react'
import '../Styles/DonateButton.css'

const DonateButton = () => {
    return (
        <button
            className='DonateButton_container'
            onClick={() => window.open("https://www.paypal.com/donate/?hosted_button_id=KMYVB9Z7UYHJS", "_blank")}
        >
            <FontAwesomeIcon icon={ faPaypal } color='white' size='1x' />&nbsp;Don
        </button>
    )
}

export default DonateButton