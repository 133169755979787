export default class Tools {
    static URL = 'https://api.tatam.lrojon.fr/'
    static TYPES = [
        { label: 'Films',       			value: 'movies' 	},
        { label: 'Séries',      			value: 'series' 	},
        { label: 'Animés',      			value: 'animes' 	},
        { label: 'Musiques',    			value: 'musics' 	},
        { label: 'Ebooks',					value: 'ebooks' 	},
        { label: 'Jeux vidéos',				value: 'games'		},
        { label: 'Logiciels',				value: 'softwares'	},
        { label: 'Applications mobiles',	value: 'apps'		},
        { label: 'Vidéos',					value: 'videos'		}
    ]
    static uuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    static getNationality = (code) => {
        switch(code.toLowerCase()) {
            case 'fr':
                return 'France'
            case 'us':
                return 'USA'
            case 'gb':
                return 'Royaume-Uni'
            case 'de':
                return 'Allemagne'
            case 'it':
                return 'Italie'
            case 'es':
                return 'Espagne'
            case 'ca':
                return 'Canada'
            case 'au':
                return 'Australie'
            case 'jp':
                return 'Japon'
            case 'cn':
                return 'Chine'
            case 'br':
                return 'Brésil'
            case 'mx':
                return 'Mexique'
            case 'ru':
                return 'Russie'
            case 'kr':
                return 'Corée du Sud'
            case 'tr':
                return 'Turquie'
            case 'in':
                return 'Inde'
            case 'ar':
                return 'Argentine'
            case 'at':
                return 'Autriche'
            case 'be':
                return 'Belgique'
            case 'bg':
                return 'Bulgarie'
            case 'cl':
                return 'Chili'
            case 'co':
                return 'Colombie'
            case 'cz':
                return 'République tchèque'
            case 'dk':
                return 'Danemark'
            case 'fi':
                return 'Finlande'
            case 'gr':
                return 'Grèce'
            case 'hu':
                return 'Hongrie'
            case 'ie':
                return 'Irlande'
            case 'is':
                return 'Islande'
            case 'lt':
                return 'Lituanie'
            case 'lu':
                return 'Luxembourg'
            case 'lv':
                return 'Lettonie'
            case 'nl':
                return 'Pays-Bas'
            case 'no':
                return 'Norvège'
            case 'pl':
                return 'Pologne'
            case 'pt':
                return 'Portugal'
            case 'ro':
                return 'Roumanie'
            case 'se':
                return 'Suède'
            case 'ch':
                return 'Suisse'
            case 'sk':
                return 'Slovaquie'
            case 'si':
                return 'Slovénie'
            case 'za':
                return 'Afrique du Sud'
            case 'tw':
                return 'Taiwan'
            case 'th':
                return 'Thaïlande'
            case 'vn':
                return 'Vietnam'
            case 'tr':
                return 'Turquie'
            case 'ua':
                return 'Ukraine'
            case 'ae':
                return 'Emirats Arabes Unis'
            default:
                return 'Inconnu'
        }
    }
}