import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import '../../Styles/ItemModal.css'

const MovieOptionItem = ({ option }) => {
    return (
        <button
            className='MovieOption_container'
            onClick={() => window.open(option.link, '_blank')}
        >
            <span>{option.quality}</span>
            <span>{option.language}</span>
        </button>
    )
}

const MovieModalItem = ({ item, onClose }) => {
    for(let genre of item.genres) {
        if(genre === "Science-Fiction") {
            genre = "SF"
        }
    }

    return (
        <div key={item.title + '-' + item.image} className='ItemModal_container'>
            <div className='header' >
                <h2>{item.title}</h2>
                <button onClick={(e) => onClose(e)} >
                    <FontAwesomeIcon icon={ faArrowLeft } color='white' size='3x' />
                </button>
            </div>
            <div className='content' >
                <div className='left'>
                    <div className='img' >
                        <img src={item.image} alt={item.title} />
                    </div>
                    <div className='infoSection' >
                        <div>
                            <strong>Durée: </strong>
                            {item.duration}
                        </div>
                        <div>
                            <strong>Sortie: </strong>
                            {item.releaseYear}
                        </div>
                        <div>
                            <strong>Genres: </strong>
                            {item.genres.join(', ').substring(0, 20) + (item.genres.join(', ').length > 20 ? '...' : '')}
                        </div>
                        <div>
                            <strong>Nationalité: </strong>
                            {item.nationality.join(', ')}
                        </div>
                    </div>
                </div>
                <div className='textSection' >
                    <div className='optionSection' >
                        {
                            item.options.map(el => <MovieOptionItem key={el.quality + el.language} option={el}/>)
                        }
                    </div>
                    <div className='placeholder' >
                        {/* AD ? */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MovieModalItem