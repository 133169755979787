import './Styles/App.css';
import Header from './Components/Header';
import List from './Components/List';
import { useEffect, useRef, useState } from 'react';
import Tools from './Helpers/Tools';
import Loading from './Components/Loading';
import icon from './Assets/icon.png'
import { isMobile } from 'react-device-detect';

function App() {

	const [list, setList] = useState([])
	const [type, setType] = useState("movies")
	const [loading, setLoading] = useState(false)
	const [search, setSearch] = useState("")
	const [home, setHome] = useState(true)
	const divHome = useRef(null)

	const searchWeb = (value, type) => {
		setHome(false)
		if(value.trim() !== "") {
			setList([])
			setLoading(true)
			fetch(Tools.URL + type + '/' + value)
				.then(res => res.json())
				.then(data => {
					setLoading(false)
					setList(data)
					setType(type)
				})
				.catch(err => alert('Erreur du serveur. Réessayer plus tard'))
		}
		else {
			setHome(true)
			setList([])
		}
	}
	useEffect(() => {
		if(divHome.current) {
			setTimeout(() => {
				divHome.current.classList.toggle('animation')
			}, 300);
		}
	}, [home])


	if(isMobile) {
		return (
			<div className="App_container mobile">
				<img src={icon} alt="icon" />
				<button onClick={ () => { window.open('https://play.google.com/store/apps/details?id=fr.lrojon.tatam&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1', "_blank") } }>
					<img alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'/>
				</button>
			</div>
		)
	}
	return (
		<div className="App_container">
			<Header 
				search={search}
				setSearch={setSearch}
				onSearch={searchWeb}
				type={type}
				setType={setType}
			/>
			{
				loading ?
					<Loading />
				:
					list.length > 0 ?
						<List
							type={type}
							list={list}
						/>
					:
						home ?
							<div ref={divHome} className="home">
								<img src={icon} alt="icon" />
								<p>Tatam</p>
							</div>
						:
							<div className="notFound">
								Non trouvé
							</div>
			}
		</div>
	);
}

export default App;
