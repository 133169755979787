import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin } from 'react-loader-spinner';
import '../Styles/Loading.css'

const Loading = () => {
    return (
        <div className='Loading_container' >
            <TailSpin
                color='#CC6600'
                width={100}
                height={100}
                ariaLabel='Chargement'
            />
        </div>
    )
}

export default Loading