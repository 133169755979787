import React from 'react'
import '../Styles/Modal.css'

const Modal = ({ children, classname, isShown, onRequestClose }) => {
    return (
        <div 
            className={'Modal_wrapper' + (isShown ? ' ' : ' hidden')}
            onClick={(e) => {
                e.stopPropagation()
                onRequestClose()
            }}
        >
            <div 
                className={'Modal_container ' + classname + (isShown ? ' ' : ' hidden')}
                onClick={(e) => e.stopPropagation()}
            >
                { children }
            </div>
        </div>
    )
}

export default Modal