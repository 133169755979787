import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import '../../Styles/ItemModal.css'

const SerieOptionItem = ({ option }) => {
    return (
        <button
            className='MovieOption_container'
            onClick={() => window.open(option.link, '_blank')}
        >
            <span>{option.quality}</span>
            <span>{option.language}</span>
        </button>
    )
}

const SeriesModalItem = ({ item, onClose }) => {
    const [selectedSeason, setSelectedSeason] = useState(0)
    const [selectedEpisode, setSelectedEpisode] = useState(0)

    return (
        <div key={item.title + '-' + item.image} className='ItemModal_container'>
            <div className='header' >
                <h2>{item.title}</h2>
                <button onClick={(e) => onClose(e)} >
                    <FontAwesomeIcon icon={ faArrowLeft } color='white' size='3x' />
                </button>
            </div>
            <div className='content' >
                <div className='left'>
                    <div className='img' >
                        <img src={item.image} alt={item.title} />
                    </div>
                    <div className='infoSection' >
                        <div>
                            <strong>Saisons: </strong>
                            {item.seasons.length}
                        </div>
                        <div>
                            <strong>Genres: </strong>
                            {item.genres.join(', ').substring(0, 20) + (item.genres.join(', ').length > 20 ? '...' : '')}
                        </div>
                        <div>
                            <strong>Nationalité: </strong>
                            {item.nationality.join(', ').substring(0, 20) + (item.genres.join(', ').length > 20 ? '...' : '')}
                        </div>
                    </div>
                </div>
                <div className='textSection' >
                    <div className='seriesSelection' >
                        <select
                            value={selectedSeason}
                            onChange={(e) => {
                                setSelectedSeason(e.target.value)
                                setSelectedEpisode(0)
                            }}
                        >
                            {
                                item.seasons.map((season, index) => {
                                    return (
                                        <option key={season.title + '-' + index} value={index}>Saison {season.nb}</option>
                                    )
                                })
                            }
                        </select>
                        <select
                            value={selectedEpisode}
                            onChange={(e) => setSelectedEpisode(e.target.value)}
                        >
                            {
                                item.seasons[selectedSeason].episodes.map((episode, index) => {
                                    return (
                                        <option key={episode.title + '-' + index} value={index}>{episode.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='optionSection series' >
                        {
                            item.seasons[selectedSeason].episodes[selectedEpisode].options.map(el => <SerieOptionItem key={el.quality + el.language} option={el}/>)
                        }
                    </div>
                    <div className='placeholder' >
                        {/* AD ? */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeriesModalItem